<template>
  <modal
    name="editAdquirente"
    height="auto"
    :scrollable="true"
    :clickToClose="false"
    @before-close="resetEdit"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div style="color: #999; font-size: 80%">EDITANDO ADQUIRENTE</div>
        <h1 class="title">
          <div>{{ `${adquirente.nome}` }}</div>
        </h1>
        <div v-if="message" class="bdti-alert bdti-alert-success">
          {{ message }}
        </div>
        <div v-if="error" class="bdti-alert bdti-alert-danger">{{ error }}</div>
      </div>

      <form>
        <div class="xrow">
          <div class="form-group">
            <div class="col-1-1">
              <label for>Nome</label>
              <input
                type="text"
                class="form-control"
                v-model="adquirente.nome"
              />
              <div class="message">
                {{ validation.firstError("adquirente.nome") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2">
              <label for>Gateway</label>
              <select
                class="form-control"
                v-model="adquirente.gateway"
                v-on:change="cleanUnusedFields"
              >
                <option
                  v-for="(gateway, key) in gatewaysDisponiveis"
                  v-bind:key="key"
                  :value="key"
                >
                  {{ gateway }}
                </option>
              </select>
              <div class="message">
                {{ validation.firstError("adquirente.gateway") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2">
              <label for>Ambiente</label>
              <select class="form-control" v-model="adquirente.ambiente">
                <option value="HOMOLOGACAO">HOMOLOGACAO</option>
                <option value="PRODUCAO">PRODUCAO</option>
              </select>
              <div class="message">
                {{ validation.firstError("adquirente.ambiente") }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-1-2">
              <label for>Tipo de Cobrança</label>
              <select class="form-control" v-model="adquirente.cobranca_id">
                <option
                  v-for="cobranca in cobrancasDisponiveis"
                  v-bind:key="cobranca.id"
                  :value="cobranca.id"
                >
                  {{ cobranca.desc }}
                </option>
              </select>
              <div class="message">
                {{ validation.firstError("adquirente.cobranca_id") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2">
              <label for>Ordem</label>
              <select class="form-control" v-model="adquirente.ordem">
                <option
                  v-for="(ordem, index) in ordensDisponiveis"
                  v-bind:key="index"
                  :value="ordem"
                >
                  {{ ordem }}
                </option>
              </select>
              <div class="message">
                {{ validation.firstError("adquirente.ordem") }}
              </div>
            </div>
          </div>
          <div class="form-group" v-if="inputShouldBeVisible('merchant_key')">
            <div class="col-1-2">
              <label for>{{ adquirente.gateway | merchantKeyLabel }}</label>
              <input
                type="text"
                class="form-control"
                v-model="adquirente.merchant_key"
              />
              <div class="message">
                {{ validation.firstError("adquirente.merchant_key") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2">
              <label for>{{ adquirente.gateway | publicKeyLabel }}</label>
              <input
                type="text"
                class="form-control"
                v-model="adquirente.merchant_id"
              />
              <div class="message">
                {{ validation.firstError("adquirente.merchant_id") }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div
              class="col-1-2"
              v-if="inputPixSicrediShouldBeVisible('certificado_psp')"
            >
              <label>Certificado PSP (.pem/.cer)</label>
              <input type="file" class="form-control" v-on:change="setPsp" />
              <span>{{ getFileName(adquirente.certificados_link.certificado_psp_name) }}</span>
              <div class="message"></div>
            </div>
            <div
              class="col-1-2"
              v-if="inputPixSicrediShouldBeVisible('certificado_app')"
            >
              <label>Certificado APP (.key)</label>
              <input type="file" class="form-control" v-on:change="setApp" />
              <span>{{ getFileName(adquirente.certificados_link.certificado_app_name) }}</span>
              <div class="message"></div>
            </div>
          </div>
          <div class="form-group" v-if="inputPixSicrediShouldBeVisible('senha_certificado_app')">
            <div class="col-1-2">
              <label for>Senha do certificado APP</label>
              <input
                type="text"
                class="form-control"
                v-model="adquirente.senha_certificado_app"
              />
              <div class="message">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2" v-if="inputPixSicrediShouldBeVisible('chave_pix')">
              <label for>Chave Pix</label>
              <input type="text" class="form-control" v-model="adquirente.chave_pix">
              <div class="message">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-2" v-if="inputShouldntBeVisible('processa_pgto')">
              <label for>Processar Pagamento em:</label>
              <select class="form-control" v-model="adquirente.processa_pgto">
                <option :value="1">1 Etapa (autorização com captura)</option>
                <option :value="2">2 Etapas (autorização e captura)</option>
              </select>
              <div class="message">
                {{ validation.firstError("adquirente.processa_pgto") }}
              </div>
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Filiais de exibição</label>
                <label v-for="filial in filiais">
                  <input
                    type="checkbox"
                    v-model="adquirente.filiais"
                    :value="filial.id"
                  />
                  {{ filial.razSoc }}
                </label>
                <div class="message">
                  {{ validation.firstError("adquirente.filiais") }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-1-1">
              <label for>Instruções (opcional):</label>
              <ckeditor
                type="classic"
                v-model="adquirente.instrucoes"
                :config="editorConfig"
                :editor="editor"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        :class="{
          'button button-success': true,
          'button-disabled': validation.hasError(),
        }"
        :disabled="validation.hasError()"
        v-on:click="handleSubmit"
      >
        Salvar
      </button>
      <button type="button" class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js";
import CloseModal from "../../../../components/CloseModal";
import { file } from "@babel/types";
import jsonFormData from "json-form-data";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório.",
    greaterThan: "Valor deve ser maior que {0}",
  },
});

const linkGateways    = ["PICPAY", "PIX"];
const linkGatewaysPix = ["PICPAY", "PIX", "REDECARD", "CIELO"];
const linkGatewaysOff = ["PIX_SICREDI"];

export default {
  name: "adquirente-edit",
  props: [
    "adquirente",
    "cobrancasDisponiveis",
    "gatewaysDisponiveis",
    "ordensDisponiveis",
    "modoDeEdicao",
    "salvar",
    "close",
    "message",
    "error",
    "resetEdit",
  ],
  components: {
    CloseModal,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "pt-br",
        link: {
          addTargetToExternalLinks: true,
        },
      },
      filiais: [],
    };
  },
  filters: {
    publicKeyLabel(gateway) {
      switch (gateway) {
        case "PICPAY":
          return "Seller ID";

        case "PIX":
          return "Chave PIX";

        case "PIX_SICREDI":
          return "Client Id";

        default:
          return "Public Key (PV)";
      }
    },
    merchantKeyLabel(gateway) {
      switch (gateway) {
        case "PIX_SICREDI":
          return "Client Secret";

        default:
          return "Merchant Key (Token)";
      }
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.salvar(this.modoDeEdicao, this.adquirente);
    },
    inputShouldBeVisible() {
      return linkGateways.indexOf(this.adquirente.gateway) < 0;
    },
    inputShouldntBeVisible() {
      return linkGatewaysOff.indexOf(this.adquirente.gateway) < 0;
    },
    inputPixSicrediShouldBeVisible() {
      return linkGatewaysPix.indexOf(this.adquirente.gateway) < 0;
    },
    cleanUnusedFields() {
      this.adquirente.merchant_key = "";
      this.adquirente.processa_pgto = 1;
      this.adquirente.certificados = [];
    },
    getFileName(filePath) {
      if (filePath) {
        var splitedPath = filePath.split("/");
        return splitedPath[3];
      }
      return "Sem arquivo";
    },
    setApp(e) {
      if (e) {
        this.adquirente.certificados.forEach(certificado => {
          if(certificado.name === 'app'){
              this.adquirente.certificados.splice(certificado);
          }
        });
        this.adquirente.certificados.push(
          {
            'file': e.target.files[0],
            'name': 'app'
          }
        );
      }
    },
    setPsp(e) {
      if (e) {
        this.adquirente.certificados.forEach(certificado => {
          if(certificado.name === 'psp'){
              this.adquirente.certificados.splice(certificado);
          }
        });
        this.adquirente.certificados.push(
          {
            'file': e.target.files[0],
            'name': 'psp'
          }
        );
      }
    }
  },
  validators: {
    "adquirente.nome": (value) => Validator.value(value).required(),
    "adquirente.gateway": (value) => Validator.value(value).required(),
    "adquirente.cobranca_id": (value) => Validator.value(value).required(),
    "adquirente.ambiente": (value) => Validator.value(value).required(),
    "adquirente.ordem": (value) => Validator.value(value).required(),
    "adquirente.merchant_id": (value) => Validator.value(value).required(),
    // 'adquirente.processa_pgto, adquirente.gateway': (processaPgto, gateway) => {
    //   if (linkGateways.indexOf(gateway) < 0) {
    //     return Validator.value(processaPgto).required();
    //   }
    // },
    // 'adquirente.merchant_key, adquirente.gateway': (merchantKey, gateway) => {
    //   if (linkGateways.indexOf(gateway) < 0) {
    //     return Validator.value(merchantKey).required();
    //   }
    // },
  },
  beforeMount() {
    const { filiais } = JSON.parse(localStorage.getItem("app"));
    this.filiais = filiais;
  },
};
</script>
